(function (scope) {
    "use strict"; 

    var $ = scope.jQuery, af = scope.af, c = af.common, shared = {
        htmlMain: "<form class='sr-only'>" +
		        "<input type='file' multiple name='File' id='<%=FileInputID%>'>" +
		        "<input type='hidden' name='filename'/>"+
	         "</form>",
        htmlMainSingle: "<form class='sr-only'>" +
		        "<input type='file'  name='File' id='<%=FileInputID%>'>" +
		        "<input type='hidden' name='filename'/>"+
	         "</form>",
        label: "<label for='<%=FileInputID%>' class='btn btn-default'><%=FileText%></label>",
        backdropMarkupContainer:"<div class='hide backdrop text-center bg-dark position-absolute' style='opacity: 0.7; z-index:1500;'></div>",
       // backdropMarkupContainer:"<div class='hide backdrop text-white modal-backdrop show text-center'></div>",
        backdropMarkup:"<h3 class='text-white pt-4 mt-4'><%=DropHoverText%></h3>",
      
        backdrop:null,
        fileInputCounter: 0,
        progressContainer:      "<div class='modal' tabindex='-1' data-backdrop='static' data-keyboard='false' role='dialog'>"+
                                "    <div class='modal-dialog' role='document'>"+
                                "        <div class='modal-content'>"+
                                "          <div class='modal-body p-3'>"+
                                "               <h5 class='modal-title mb-2 uploadingProgress'><%=Uploading%> <span data-current></span> <%=of%> <span data-total></span></h5>"+
                                "               <h5 class='modal-title mb-2 hidden uploadComplete'><%=UploadComplete%></h5>"+
                                "               <h5 class='modal-title mb-2 hidden uploadsComplete'></h5>"+
                                "               <div class='fileErrorContainer alert alert-warning hidden'></div>"+
                                "               <div class='fileUploadContainer'></div>"+
                                "               <div class='filesErrorContainer alert alert-warning hidden'></div>"+
                                "               <div class='checkbox float-left'>"+
                                "                   <label>"+
                                "                       <input class='' type='checkbox' checked>"+
                                "                       <%=CloseWhenComplete%>"+
                                "                   </label>"+
                                "               </div>"+
                                "                <button class='btn btn-warning btn-sm cancelUpload float-right' ><%=Cancel%></button>"+
                                "                <button class='btn btn-primary btn-sm closeUpload hidden float-right' ><%=Close%></button>"+
                                "                <button class='btn btn-primary btn-sm pauseUpload float-right mr-1' ><%=Pause%></button>"+
                                "          </div>"+
                                "        </div>"+
                                "    </div>"+
                                "</div>",
        progressFileMarkup:     "<div class='progress text-nowrap'>"+
                                "    <div class='progress-bar pt-1' style='height:100%' role='progressbar' aria-valuenow='<%=UploadedPercents%>' aria-valuemin='0' aria-valuemax='100'><%=UploadedPercents%> %"+
                                "   </div>"+
                                "</div>"+
                                "<div class='small text-center mb-2' data-progress-info><%=FileName%> <%=FileUploadedSize%> <%=of_total%> <%=FileTotalSize%></div>",
        progress:null,
        progressTotalLabel:null,
        autoClosekey:'FileUploadAutoClose',
        init:false,
        paused:false,
        allowedExtensions: null,
        fileExtensions: null
    };
    
    function FileUpload(pOptions) {
        var that = this,
            priv = {
                options: new c.Options({
                    defaults: {
                        fieldName: null,
                        uploadUrl: null,
                        MAX_WIDTH: 1920, /*When uploading images*/
                        MAX_HEIGHT: 1080,/*When uploading images*/
                        hideBackdrop:false,
                        sliceSize:4*1024*1024,
                        minUseCrcSize:10*1024*1024*10
                    },
                    required: ["element","id"],
                    passed: pOptions
                    
                }),
                eventHandler: new c.EventHandler(that, {
                    onFilesUploaded: { abortable: false }, /*  */
                    onUploaded: { abortable: false }, /*  */
                    onBeforeUpload: { abortable: false }, /*  */
                    onManualUpload: { abortable: false }, /*  */
                    onError: { abortable: true }, /*  */
                    onCancel: { abortable: true }, /*  */
                    onBeforePaste: { abortable: true }, /*  */
                    onFilesSelected: { abortable: false } /*  */
                }),
                element: null,
                dropArea: null,
                dropHoverText: pOptions.dropHoverText,
                fileInput: null,
                fileTypes: null,
                clickTarget: null,
                filesToUpload: {},
                primKey: null,
                componentsUploader: null,
                form:null,
                binaryField:null,
                dataObject:null,
                routeUrl:pOptions.routeUrl?pOptions.routeUrl:null,
                appendFiles:false,
                fileInputID: "FileUploadInput" + (++shared.fileInputCounter),
                showProgress: pOptions.showProgress === false ? false : true,
                filesBlobMap: [],
                filesMetaData: [],
                useChunkUpload: true,
                uploadUrl:null,
                currentBlob: {},
                filesArray : [],
                interval:null,
                blob:[],
                progress:[],
                cancel:false,
                xhr:null,
                currentBlobIndex: 0,
                paused: false,
                error: null,
                worker:null,
                timeout: null,
                init:false,
                events:{},
                addExifData:false,
                exifData:null,
                customHeaders: null,
                multipleFilesPathField:null,
                pasteEventBinded:false,
                disablePasteEvent: false,
                refreshDs:true,
                disableUpload:false,
                tmpData: null
            };
    
        function fireEvent(pEvent, pArgs, pModifiableArgs) {
            return priv.eventHandler.fire(pEvent, pArgs, pModifiableArgs);
        }
        function attachEvent(pEvent, pFunc) {
            return priv.eventHandler.attach(pEvent, pFunc);
        }
        function detachEvent(pEvent, pFunc) {
            return priv.eventHandler.detach(pEvent, pFunc);
        }
        
        function initElement() {
            priv.element = priv.options.element;
            priv.binaryField = ($(priv.element).data("binary")?$(priv.element).data("binary"):"");
          
            if(!shared.extensionsRetrieved){
                shared.extensionsRetrieved = true;
                // getFileExtensions();
            }

            if(!shared.backdrop){
                shared.backdrop = $(shared.backdropMarkupContainer);

                $("body").append(shared.backdrop);
      
                 $(shared.backdrop).on("dragstart", function(e){
                    if(!IsJsonString(e.dataTransfer.getData("text"))){
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
                $(shared.backdrop).on("dragover", function(e){
                    e.originalEvent.dataTransfer.dropEffect = "copy";
                    if(!IsJsonString(e.dataTransfer.getData("text"))){
                        if(shared.activeFileUpload.hasBackdrop()){
                            var dt = e.originalEvent.dataTransfer;
                            if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))) 
                                shared.backdrop.removeClass("hide");
                            
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }
                });
                $(shared.backdrop).on("dragenter", function(e){
                    if(!IsJsonString(e.dataTransfer.getData("text"))){
                        e.preventDefault();
                        e.stopPropagation();
                        
                    }
                });
                $(shared.backdrop).on("dragleave", function(e){
                    if(!IsJsonString(e.dataTransfer.getData("text"))){
                        if(shared.activeFileUpload.hasBackdrop()){
                            shared.backdrop.addClass("hide");
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }
                });
                $(shared.backdrop).on("drop", function(e){
                    if(!IsJsonString(e.dataTransfer.getData("text"))){
                        if (e.dataTransfer && e.dataTransfer.files.length) {
                            e.originalEvent.dataTransfer.dropEffect = "copy";
                        }
                        if(shared.activeFileUpload.hasBackdrop()){
                            shared.backdrop.addClass("hide");
                            shared.activeFileUpload.onDrop(e);
                        }
                    }
                });
            }

            $(priv.element).on("dragover", function(e){
                if(!IsJsonString(e.dataTransfer.getData("text"))){
                    e.originalEvent.dataTransfer.dropEffect = "copy";
                    shared.activeFileUpload = that;
                    if(shared.backdrop && shared.activeFileUpload.hasBackdrop()){
                        shared.backdrop.css("left", $(e.currentTarget).offset().left);
                        shared.backdrop.css("top", $(e.currentTarget).offset().top);
                        shared.backdrop.css("width", $(e.currentTarget).width());
                        shared.backdrop.css("height", $(e.currentTarget).height());
                        var allTypes = [];
                        for(var i = 0; i< e.originalEvent.dataTransfer.items.length; i++){ 
                            allTypes.push(e.originalEvent.dataTransfer.items[i].type)
                        }
                        shared.backdrop.html(that.getBackdrop(allTypes));
                        var dt = e.originalEvent.dataTransfer;
                        if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files')))
                            shared.backdrop.removeClass("hide");
                    }
                    
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
            
            $(priv.element).on("dragenter", function(e){
                if(!IsJsonString(e.dataTransfer.getData("text"))){
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
            
            $(priv.element).on("dragleave", function(e){
                if(!IsJsonString(e.dataTransfer.getData("text"))){
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
            
            $(priv.element).on("drop", function(e){
                if(!IsJsonString(e.dataTransfer.getData("text"))){
                    if (e.dataTransfer && e.dataTransfer.files.length) {
                        e.originalEvent.dataTransfer.dropEffect = "copy";
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }
            });

            if($(priv.element).data("single") === undefined){
                $(priv.element).append(shared.htmlMain.replace("<%=FileInputID%>", priv.fileInputID));
            }else{
                $(priv.element).append(shared.htmlMainSingle.replace("<%=FileInputID%>", priv.fileInputID));
            }

            priv.dropArea = priv.element;
            priv.form = priv.element.getElementsByTagName("FORM")[0];
            priv.fileInput = priv.form.childNodes[0];
            
            if(!priv.routeUrl){
                console.warn("Migrate.FileUpload doesn ot support custom routes!");
                priv.routeUrl = $(priv.element).data("route-url");
            }
            if($(priv.element).data("multiple") === false){
                $(priv.element).find("input").removeAttr("multiple")
            }

            if($(priv.element).data("allowed-filetypes")){
                priv.fileTypes = $(priv.element).data("allowed-filetypes").toLowerCase().replace(/ /g,"").split(",");
                let vAccept = priv.fileTypes.map(type => `.${type}`).join(', ');
                $(priv.element).find(`#${priv.fileInputID}`)[0].setAttribute('accept', vAccept);
            }
            
            if($(priv.element).data("disable-paste") !== undefined){
                priv.disablePasteEvent = true;
            }

            if($(priv.element).data("upload-refreshds") === false){
                priv.refreshDs = false;
            }
            

            if(priv.options.id){
                if (priv.fileInput.files === undefined) {
                    var vText = $(priv.clickTarget).text();
                    $(priv.clickTarget).replaceWith(shared.label.replace("<%=FileText%>", vText).replace("<%=FileInputID%>", priv.fileInputID));
                } else {
                    $("body").on("click","[data-upload-target=" + priv.options.id + "]:not('[data-tree-id]')",function () {
                        shared.activeFileUpload = that;

                        priv.fileInput.click();
                    });
                }
            }
            
            priv.fileInput.onchange = function () {
                if(priv.disableUpload){
                    return;
                }
                 
                fireEvent("onFilesSelected");

                if(!priv.dataObject){
                    priv.dataObject = af.DataObject.getByID($(priv.element).closest("[data-object-id]").data("object-id"));
                }
                shared.activeFileUpload = that;
                if(priv.routeUrl){
                    var vRow = priv.dataObject?priv.dataObject.currentRow():null;
                    if(vRow){
                        priv.uploadUrl = af.dataBinding.Helpers.validateSrcUrl(null,priv.routeUrl, vRow);//priv.routeUrl;    
                    }else{
                        priv.uploadUrl = priv.routeUrl;
                    }
                }else if(!priv.uploadUrl && priv.dataObject){
                    priv.uploadUrl = (priv.options.uploadUrl !== null ?priv.options.uploadUrl : ("/api/file/chunkupload/"));
                }
                
                if(!priv.componentsUploader){
                    priv.componentsUploader = new af.components.Uploader({ url: priv.uploadUrl });        
                }
                
                if (!priv.fileInput.files) {
                    var upload = priv.componentsUploader.upload(priv.form);

                    upload.addEventListener("success", function (ev) {
                        var result = JSON.parse(ev.response);
                        if (result.error) {
                            alert(result.error);
                        }
                        fireEvent("onUploaded", result);
                    });
                    upload.start();
                } else {
                    if (priv.fileInput.files.length === 0) { return; }

                    var allTypes = [];
                    for(var i=0; i<priv.fileInput.files.length; i++){
                        
                        if(priv.fileInput.files[i].name.lastIndexOf('.') != -1){
                            //Get extention from fileName
                            allTypes.push(priv.fileInput.files[i].name.split('.').pop());
                        }else{
                            allTypes.push(priv.fileInput.files[i].type);
                        }
                    }
                    if(!isFilePermitted(allTypes)){
                        alert(getWrongExtensionMessage());
                    }
                    if(!priv.permitted) return;

                    addFilesToFileList(priv.fileInput.files);

                    priv.tmpData = getFormData();
                    
                    if (!priv.options.manualUpload) {
                        save(function (pFiles) {
                            fireEvent("onFilesUploaded", pFiles);
                        });
                    } else {
                        fireEvent("onManualUpload",priv.fileInput.files);
                    }
                }
            };
        }
        
        function initProgress(){
            shared.progress = $(shared.progressContainer);
            shared.progress = $(af.template.run(shared.progressContainer, {
                Cancel:aT("Cancel"),
                Close:aT("Close"),
                Uploading:aT("Uploading file"),
                UploadComplete:aT("File upload completed"),
                UploadsComplete:aT("files uploaded"),
                of:aT("of"),
                CloseWhenComplete:aT("Close when complete"),
                Pause:aT("Pause"),
            }));
            
            if(af.common.localStorage.get(shared.autoCloseKey,true,true)){
                shared.progress.find(".checkbox input").prop("checked",true);
            }else{
                shared.progress.find(".checkbox input").prop("checked",false);
            }
            $("body").append(shared.progress);
            
            shared.progress.on("click",'.closeUpload',function(){
                closeProgress();
            });
            shared.progress.on("click",'.pauseUpload',function(){
                shared.activeFileUpload.initInterval();
                if(shared.paused){

                    shared.paused = false;
                    shared.progress.find(".fileErrorContainer").addClass('hidden');
                    $(this).text(aT("Pause"));
                    shared.activeFileUpload.resumeFileUpload();
                }else{
                    shared.paused = true;
                    $(this).text(aT("Resume"));
                   
                }
            });
            shared.progress.on("click",'.cancelUpload',function(){
                shared.activeFileUpload.initInterval();
     
                if(shared.activeFileUpload.getXhr() !== null){
                    shared.activeFileUpload.getXhr().abort();
                }
                shared.activeFileUpload.cancelFileUpload();
            });
            
            shared.progress.on("click",'.checkbox input',function(){
                af.common.localStorage.set(shared.autoCloseKey,$(this).is(":checked"),true);
            });
        }

        function attachEvents() {
            priv.dropArea.addEventListener("dragover", onDragOver);
            priv.dropArea.addEventListener("dragenter", onDragEnter);
            priv.dropArea.addEventListener("dragleave", onDragLeave);
            priv.dropArea.addEventListener("drop", onDrop);
        }
        
        function IsJsonString(str) {
            if(str === null || str === ""){
                return false;
            }
            try {
                
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            if(str.length === 0){
                return false;
            }else{
                return true;
            }
        }
        
        
        function onDragOver(e) { 
            e.preventDefault(); 
            e.stopPropagation(); 
            $(this).addClass("over"); 
           
            
        }
        function onDragEnter(e) { e.preventDefault(); e.stopPropagation(); }
        function onDragLeave(e) { e.preventDefault(); e.stopPropagation(); $(this).removeClass("over"); }
        function onDrop(pEvent) {
            onDrop(pEvent);
        }
        
        function onDrop(pEvent){
            if(priv.disableUpload){
                var v = af.controls.toast(aT("Upload disabled"));
                setTimeout(function(){
                    v();
                },2000)
                return;
            }
            pEvent.preventDefault();
            //pEvent.stopPropagation();
            $(pEvent.currentTarget).removeClass("over");
            
           var allTypes = [];
            for(var i = 0; i< pEvent.dataTransfer.files.length; i++){ 
                allTypes.push(pEvent.dataTransfer.files[i].type)
            }
            if(!isFilePermitted(allTypes)){
                alert(getWrongExtensionMessage());
                return;
            }

            if (pEvent && pEvent.dataTransfer && pEvent.dataTransfer.files && pEvent.dataTransfer.files.length > 0) {
                if (pEvent.dataTransfer.files.length === 0) { return; }

                // stop if there are any folders dropped
                for (var i = 0; i < pEvent.dataTransfer.files.length; i++) {
                    if (!pEvent.dataTransfer.files[i].type && pEvent.dataTransfer.files[i].size % 4096 === 0 && pEvent.dataTransfer.files[i].name.indexOf(".") == -1) {
                        alert("Folders cannot be dropped here. Please select files only.");
                        return;
                    }
                }
                addFilesToFileList(pEvent.dataTransfer.files);
                
                if(!priv.options.manualUpload){
                    save(function (pFiles) {
                        fireEvent("onFilesUploaded", pFiles);
                        if(shared.backdrop){
                            shared.backdrop.addClass("hide");
                        }
                    });
                } else {
                    fireEvent("onManualUpload",pEvent.dataTransfer.files);
                    if(shared.backdrop){
                        shared.backdrop.addClass("hide");
                    }
                }
            }
        }
        
        function addFilesToFileList(pFiles){
            if(priv.appendFiles){
                $.each(pFiles, function(key,value){
                    if(!checkIfFileExists(value)){
                        priv.filesToUpload[priv.filesToUpload.length?priv.filesToUpload.length:0] = value;
                        priv.filesToUpload.length = priv.filesToUpload.length?priv.filesToUpload.length + 1:1;
                    }
                });
            }else{
                let files = Array.from(pFiles).sort((a,b) => { 
                          if ( a.size < b.size ){
                            return -1;
                          }
                          if ( a.size > b.size ){
                            return 1;
                          }
                          return 0;

                });
                priv.filesToUpload = files;                
            }
        }
        
        function checkIfFileExists(pFile){
            var vExists = false;
            $.each(priv.filesToUpload,function(key,value){
                if(value && value.constructor === File){
                    if(pFile.name && value.name && pFile.size && value.size){
                        if(pFile.name === value.name && pFile.size === value.size){
                            vExists = true;
                            return vExists;
                        }
                    }
                }
            });
            return vExists;
        }
       
        
        function save(pCallback) {
            priv.exifData = null;
            shared.activeFileUpload = that;
            var vRequest = new afXMLHttpRequest(),
                vLinkFields,pFiles = priv.filesToUpload;
            
            if(!priv.dataObject){
                priv.dataObject = af.DataObject.getByID($(priv.element).closest("[data-object-id]").data("object-id"));
            }
           
            if(priv.routeUrl || priv.binaryField){
                var vRow = priv.dataObject?priv.dataObject.currentRow():null;
                    if(priv.binaryField !== "" && !priv.uploadUrl){
                        console.warn('migrate.FileUpload handler does not support custom filestore table upload!');
                        // priv.uploadUrl = "/file/upload/" + priv.dataObject.getDatasourceArticleID() + "/" + priv.dataObject.getDataSourceId() + "/" + priv.binaryField;
                        // priv.routeUrl =  priv.uploadUrl;
                    }else if(priv.routeUrl){
                        if(vRow){
                            priv.uploadUrl = af.dataBinding.Helpers.validateSrcUrl(null,priv.routeUrl, vRow);    
                        }else{
                            priv.uploadUrl = priv.routeUrl;
                        }
                    }
            }else if(!priv.uploadUrl && priv.dataObject){
                console.warn('migrate.FileUpload handler does not support custom filestore table upload!');
                //priv.uploadUrl = (priv.options.uploadUrl !== null ?priv.options.uploadUrl : ("/file/upload/" + priv.dataObject.getDatasourceArticleID() + "/" + priv.dataObject.getDataSourceId() + "/" + priv.binaryField));
            }
      
            if (!pFiles || pFiles.length === 0) {
                console.log("no files to upload");
            }
            if(priv.addExifData){
                getExifData(function(pFiles){
                    startUpload(pFiles,pCallback)
                });
            }else{
                startUpload(pFiles,pCallback)
            }
           
        }
        
        function startUpload(pFiles, pCallback){
            start(function(pFiles){
                priv.filesToUpload = {};
                priv.fileInput.value = "";
                priv.filesArray = [];
                if(priv.dataObject){
                    if(typeof pFiles === "string" && pFiles === priv.dataObject.currentRow("PrimKey")) {
                        priv.dataObject.refreshCurrentRow();
                    } else {
                        if(priv.refreshDs){
                            priv.dataObject.refreshDataSource();
                        }
                    }
                }

                if(pCallback){
                    pCallback(pFiles);
                }
            });
        }
        
        function checkIfFileIsDeleted(pFile){
            if(pFile.deleted === undefined){
                return false;
            }else if(pFile.deleted){
                return true;
            }else{
                return false;
            }
        }
        
        
        function getFormData(pSkipEvent){
            var formData = new FormData(),
                vLinkFields, vValidationFailed = false;
            var currentFile = priv.filesToUpload[priv.progress.currentFileIndex];
            if(priv.dataObject){
                vLinkFields = priv.dataObject.getParameter("masterChildCriteria");
            }
            
            c.forEach(vLinkFields, function (pParam, pValue) {
                if(!validateFormDataFields(pParam,pValue,priv.dataObject)) return;
                formData.append(pParam, pValue);
            });

            if(priv.multipleFilesPathField && currentFile.webkitRelativePath){
               formData.append(priv.multipleFilesPathField, currentFile.webkitRelativePath);
            }
            
            if(pSkipEvent != true) fireEvent("onBeforeUpload", formData, true);
            
            return formData;
        }

        function validateFormDataFields(pField, pValue,pDataObject){
            var vField  = pDataObject.getFields(pField), vType;
            if(!vField && pDataObject.getMasterDataObject()){
                vField = pDataObject.getMasterDataObject().getFields(pField);
            }

            if(!vField && pDataObject.getMasterDataObject() && pDataObject.getLinkFields()){
                vField = pDataObject.getLinkFields();
                if(vField) vField = pDataObject.getMasterDataObject().getFields(vField[pField]);
                
            }
            if(vField && vField.type){
                vType = vField.type;
                if(!vField.nullable && pValue === null){
                    stopUploadOnError(pField + " can not be empty");
                    
                    return false;
                }
                
               
            }
            return true;
            
        }
        
        function setCanvas(pFile, pFileName, pFormData, pCallBack) {
            var tempW, tempH, canvasObj, ctx, url, img;
            try {
                canvasObj = document.createElement("canvas");
                ctx = canvasObj.getContext("2d");
                url = URL.createObjectURL(pFile);
                img = new Image();
                img.onload = function () {
                    tempW = img.width;
                    tempH = img.height;
                    if (tempW > tempH) {
                        if (tempW > priv.options.MAX_WIDTH) {
                            tempH *= priv.options.MAX_WIDTH / tempW;
                            tempW = priv.options.MAX_WIDTH;
                        }
                    } else {
                        if (tempH > priv.options.MAX_HEIGHT) {
                            tempW *= priv.options.MAX_HEIGHT / tempH;
                            tempH = priv.options.MAX_HEIGHT;
                        }
                    }
                    canvasObj.width = tempW;
                    canvasObj.height = tempH;
                    try {
                        ctx.drawImage(img, 0, 0, tempW, tempH);
                        if(priv.binaryField){
                            pFormData.append(priv.binaryField, dataURItoBlob(canvasObj.toDataURL("image/jpeg")), pFileName);
                        }else{
                            pFormData.append("Files", dataURItoBlob(canvasObj.toDataURL("image/jpeg")), pFileName);
                        }
                    } catch (ex) {
                        c.warn("Failed to resize image using canvas: " + ex.message);
                        pFormData.append("Files", pFile, pFileName);
                    }
                    pCallBack();
                };
                img.src = url;
            } catch (ex) {
                c.warn("Failed to resize image using canvas: " + ex.message);
                pFormData.append("Files", pFile, pFileName);
                pCallBack();
            }
        }

        function dataURItoBlob(dataURI, callback) {
            var byteString, mimeString, ab, ia, i, bb, dataView, blob;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
            } else {
                byteString = unescape(dataURI.split(',')[1]);
            }

            mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            ab = new ArrayBuffer(byteString.length);
            ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            if (window.BlobBuilder || window.MSBlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder) {
                window.BlobBuilder = window.BlobBuilder || window.MSBlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;
                bb = new BlobBuilder();
                bb.append(ab);
                blob = bb.getBlob(mimeString);
            } else if (typeof Blob !== 'undefined') {
                dataView = new DataView(ab);
                blob = new Blob([dataView], { type: 'mimeString' });
            } else {
                alert('Error occurred during Blob generation');
                return null;
            }

            return blob;
        }

        function manualUpload(pManualUpload) {
            priv.options.manualUpload = pManualUpload;
        }
        

        function setCurrentPrimKey(pPrimKey) {
            priv.primKey = pPrimKey;
        }
        
        function files() {
            return priv.filesToUpload;
        }
        
        function setDropHoverText(){
            priv.dropHoverText = priv.dropHoverText?priv.dropHoverText:aT("Drop files here...");
        }
        
        function hasBackdrop(){
            return priv.options.hideBackdrop?false:true;
        }
        
        function getDropHoverText(pType){
            if(isFilePermitted(pType)){
                return priv.dropHoverText;
            }else{
                return getWrongExtensionMessage();

            }
        }

        function isFilePermitted(pType){
            if(!priv.allowedExtensions){
                var vAllowedExtensions;
                //Database and app rescrictions are present
                if(priv.fileTypes && shared.allowedExtensions){
                    vAllowedExtensions = shared.allowedExtensions.filter(function(value){return priv.fileTypes.includes(value)});
                    if(vAllowedExtensions.length == 0){
                        priv.permitted = false;
                        return false;
                    }
                }else{
                    //Only database rescriction
                    if(shared.allowedExtensions){
                        vAllowedExtensions = shared.allowedExtensions;
                    }else{
                        //Only app rescriction
                        if(priv.fileTypes){
                            vAllowedExtensions = priv.fileTypes;
                        }else{
                            //No rescrictions
                            vAllowedExtensions = [];
                        }
                    }
                }

                priv.allowedExtensions = vAllowedExtensions;
            }
            
            for(var i = 0; i < pType.length; i++){
                var currentFileExtension = getExtensionFromMIME(pType[i]);

                if(currentFileExtension == undefined && priv.allowedExtensions.findIndex(function(item){ return (pType[i] ? item.toLowerCase() === pType[i].toLowerCase() : false);})!= -1){
                    priv.permitted = true;
                    continue;
                }

                if(priv.allowedExtensions.length == 0  || (priv.allowedExtensions.length > 0 && priv.allowedExtensions.findIndex(function(item){ return (currentFileExtension ? item.toLowerCase() === currentFileExtension.toLowerCase() : false); })!= -1)){                
                    priv.permitted = true;
                }else{
                    priv.permitted = false;
                    break;
                }
            }

            if(priv.permitted === true){
                return true;
            }else{
                return false
            }
        }
        function getBackdrop(pType){
            return $(af.template.run(shared.backdropMarkup, {
                    DropHoverText: that.getDropHoverText(pType),
                }
            ));
        }
        function getWrongExtensionMessage(){
            if(priv.whitelistMessage) return priv.whitelistMessage;
            var message = af.article.i18n["File extension did not match the whitelist. Allowed extensions: "] ? af.article.i18n["File extension did not match the whitelist. Allowed extensions: "] : "File extension did not match the whitelist. Allowed extensions: ";
            return message + (priv.allowedExtensions ? priv.allowedExtensions.join(", ") : "");
        }

        function appendFiles(pAppendFiles){
            priv.appendFiles = pAppendFiles;
        }
        
        function removeFile(pFileName){
            $.each(priv.filesToUpload, function(pKey, pValue){
                if(pValue.name === pFileName){
                    priv.filesToUpload[pKey].deleted = true;
                }
            });
            
        }
        
        function removeAllFiles(){
            $.each(priv.filesToUpload, function(pKey, pValue){
               
                priv.filesToUpload[pKey].deleted = true;
              
            });
            
            
        }
        
        function removeDeletedFiles(){
            var vTempFiles = {}, length = 0;
            $.each(priv.filesToUpload, function(pKey, pValue){
                if(pKey !== 'length' && !checkIfFileIsDeleted(pValue)){
                   vTempFiles[length] = pValue;
                   length += 1;
                }
            });
            priv.filesToUpload = vTempFiles;
            priv.filesToUpload.length = length;
 
        }

        function getElement() {
            /**
            * @return {HTMLDivElement} Returns the root element of this control
            */
            return priv.fileInput;
        }

        $(function(){
            setDropHoverText();
        })

        function initialize() {
            if(!priv.init){
                initElement();
                attachEvents();
                priv.init = true;
            }
            
            if(!shared.progressTotalLabel){
                shared.progressTotalLabel = aT("of total");
            }
            
            if(!shared.progress){
                initProgress();
            }
            
        }
        
        function getXhr(){
            return priv.xhr;
        }
        /*
            function addBlob
            --used when orgonal events are overriden and we need to add files manually
            --after adding blob, need to call save
        */
        function addBlob(blob, replace){
            shared.activeFileUpload = that;
            if(replace){
                addFiles(blob);
                return;
            }
            if(priv.filesToUpload.length){
                priv.filesToUpload[priv.filesToUpload.length] = blob;
                priv.filesToUpload.length =priv.filesToUpload.length + 1;
            }else{
                priv.filesToUpload[0] = blob;
                priv.filesToUpload.length = 1;
            }
            
        }
            
            
        /**
         * pFiles - expects file input object
         * manually add files to uplaoder
         * 
         */
      
        function addFiles(pFiles){
            shared.activeFileUpload = that;
            priv.filesToUpload = pFiles;
        }
        
        function getFilesCount(){
            var vFilesCount = 0;
            $.each(priv.filesToUpload, function(pKey, pValue){
                if(pKey !== 'length'){
                    if(!checkIfFileIsDeleted(pValue)){
                        vFilesCount++;
                    }
                }
            });
            return vFilesCount;
        }
        
        function start(pCallback){
            priv.currentCallback = pCallback;
            shared.activeFileUpload.removeDeletedFiles();
            
            if(shared.backdrop){
                shared.backdrop.addClass("hide");
            }
            
            shared.paused = false;
            
            initInterval();

            if(!priv.init || !shared.progress){
                initialize();
            }
            
      
            if(priv.filesToUpload.length === undefined){
                return;
            }
            shared.progress.find('.pauseUpload').text(aT("Pause"));
            shared.progress.find('.pauseUpload').removeClass('hidden');
            shared.progress.find(".fileErrorContainer").addClass('hidden');
            prepareMetaData();
            prepareBlobMap();
            initXhr();
            
            if(!priv.routeUrl){
                reportProgress(0);
            }            
            
            getAllFilesProgress(0,function(){
                initWorker();
                calculateCrc32();
                
                priv.useChunkUpload = true;
                uploadChunk();
            });

            if(!priv.events.onUploaded){
                priv.events.onUploaded = true;
                that.attachEvent('onUploaded',function(pFiles){
                    priv.currentCallback(pFiles);
                })
            }
        }

        function initInterval(){
            if(priv.interval !== null && priv.interval.interval !== null){
                clearInterval(priv.interval.interval);
            }
            priv.interval = {
                interval:null,
                retryCounter: 3,
                repeatCounter:30
            }
        }
        
        function initXhr(){
            priv.xhr = new afXMLHttpRequest();
           
            priv.xhr.onload = function(e) { 
                if (priv.xhr.status === 200) {
                    initInterval();
                    priv.error = null;
                    if(priv.useChunkUpload){
                        handleChunkUploadCompleted(JSON.parse((e.target || e.srcElement).response));
                    }else{
                        handleUploadCompleted(JSON.parse((e.target || e.srcElement).response));
                    }
                }else{
                    if(IsJsonString((e.target || e.srcElement).response)){
                       // alert(JSON.parse((e.target || e.srcElement).response).error,"danger");
                        if (priv.xhr.status === 408) {
                           stopUploadOnError(JSON.parse((e.target || e.srcElement).response).error,true);
                        }else{
                            stopUploadOnError(JSON.parse((e.target || e.srcElement).response).error);    
                        }
                           
                    }else{
                        stopUploadOnError("Undefined error");
                    }
                    
                }
                
            };
           
            priv.xhr.onerror = function (e) {
                if(window['af.pwa.offlineDataHandler']){
                    shared.paused = true;
                    handleOfflineUpload();
                    
                }else{
                    var vText;
                    if(navigator.onLine){
                        vText = aT('Not able to upload file, file is open in another program')
                    }else{
                        vText = aT('Not able to upload file, network not accesible')
                    }

                    stopUploadOnError(vText,true);
                }
                
            };
 
            priv.xhr.upload.addEventListener("progress", function(e){
                reportProgress(e.loaded);
            },false);
            
        }
        
        function prepareMetaData(){
            var vTotal = 0,vTotalCount = 0,
                pFiles = priv.filesToUpload;
                
            priv.filesMetaData = [];
            priv.progress = {};
            for(var i = 0; i < pFiles.length;i++){
                if(pFiles[i] !== 'length' && !checkIfFileIsDeleted(pFiles[i])){
                 //   vTotalCount +=1;
                    vTotal += pFiles[i].size;
                    
                    priv.filesMetaData.push({
                        fileIndex:i,
                        action:null,
                        uploadRef:null,
                        fileRef:null,
                        completed:false,
                        totalUploaded:0,
                        tmpUploaded:0,
                        sha256CheckSum:null,
                        crc32CheckSum:null,
                        error:null,
                        binaryField:priv.binaryField
                    });
                }
            }
            priv.progress = {
                total: vTotal,
                totalUploaded : 0,   
                tmpUploaded: 0,//When not chunk
                currentFileIndex : 0,
                currentBlobIndex : 0,
                completed:false,
                currentAction:null
            }
        }
        
        function prepareBlobMap(){
            var file,
                size,
                sliceSize = priv.options.sliceSize,
                pFiles = priv.filesToUpload,
                start = 0;
            priv.filesBlobMap = [];
             
            for (var i = 0; i< pFiles.length; i++) {
                    
                file = pFiles[i];
                if(file !== 'length' && !checkIfFileIsDeleted(file)){
                    size = file.size;
                    start = 0;
                    if (size > 0){
                        for(var j = 0; j < file.size; j = j+sliceSize){
                            start = j;
                            var end = start + sliceSize-1;
                            if(end > file.size){
                                end = file.size-1;
                            }
                            
                            priv.filesBlobMap.push({index:priv.filesBlobMap.length,fileIndex:i,start:start,end:end, size:end-start+1, completed:false, uploaded:0, lastFileChunk:false});
                        }
                        
                        priv.filesBlobMap[priv.filesBlobMap.length-1].lastFileChunk = true;
                        
                    }else{
                        priv.filesBlobMap.push({index:priv.filesBlobMap.length, fileIndex:i,start:0,end:0, size:0, completed:false, uploaded:0, lastFileChunk:true});
                    }
                    
                }
            }
        }
        
        
        function getAllFilesProgress(index,pCallback){
            if(index === priv.filesToUpload.length){
                pCallback();
                return;
            }
            var vFile = priv.filesToUpload[index];
            //if(vFile.size <= priv.options.sliceSize){
            return getAllFilesProgress(index+1,function(){pCallback();});
            // }
           
            // getProgress({
            //     FileName: vFile.name,
            //     FileSize: vFile.size,
            // },function(res){
            //     var vFileMeta = priv.filesMetaData[index];
               
            //     switch(res.action) {
            //         case 'StartUpload':
            //             vFileMeta.uploadRef = res.UploadRef
            //             break;
                   
            //     }
            //     getAllFilesProgress(index+1,function(){pCallback();});
            // })
        }
        
        function getProgress(pData,pCallback){
            return; // NT does not have progress route
            if(af.userSession.personID){
                pData.DataSourceID = priv.dataObject.getDataSourceId();
                pData.ArticleID = priv.dataObject.getDatasourceArticleID();
            }
           
            var req = new afXMLHttpRequest();
            req.onload = function(e) { 
                var response = (e.target || e.srcElement);
                if (req.status === 200) {
                    pCallback((e.target || e.srcElement).response !== "" ? JSON.parse((e.target || e.srcElement).response):"");
                }else{
                    console.error((e.target || e.srcElement));
                    pCallback(null);
                }
            };
            
            req.onerror = function(e){
                if(window['af.pwa.offlineDataHandler']){
                    shared.paused = true;
                    handleOfflineUpload();
                    
                }else{
                    stopUploadOnError('Network is not accessible',true);
                    console.error(e);
                }
            }
            req.open('POST', '/api/get-upload-progress');
            req.send(JSON.stringify(pData));
            
        }
        function uploadChunk(){
            if(shared.paused){
                return;
            }
            priv.progress.currentAction = "chunk";            
            var blobIndex = priv.progress.currentBlobIndex;
            var file = priv.filesToUpload[priv.filesBlobMap[blobIndex].fileIndex];
            var start = priv.filesBlobMap[blobIndex].start,
                    end = priv.filesBlobMap[blobIndex].end,
                    chunk = slice(file, start, end+1),
                    fileMeta = priv.filesMetaData[priv.filesBlobMap[blobIndex].fileIndex];                    

            if(fileMeta.uploadRef === null){
                priv.xhr.open('POST', "/api/file/chunkupload", true);
            }else{
                priv.xhr.open('POST', "/api/file/chunkupload/"+fileMeta.uploadRef, true);
            }
            
            priv.xhr.setRequestHeader('Custom-Content-Range',  'bytes ' + start + '-' + end + '/' + file.size);

            const vFormData = new FormData();
            vFormData.append("File", chunk, prepareFileName(file.name));

            priv.xhr.send(vFormData);
        }
        
        function initWorker(){
            if(priv.worker === null){
                priv.worker = new Worker('/file/site/script/af.pwa.worker.fileUpload.js?v=12');
                priv.worker.addEventListener('message', handleWorkerMessages, false);
            }

        }
        function handleWorkerMessages(e){
            var data = e.data;
            if(priv.worker === null){
                return;
            }
            if(data === null){
                return;
            }
            switch (data.cmd) {
                case 'calculateSha256':
                    priv.filesMetaData[data.fileIndex].sha256CheckSum = data.sha256CheckSum;
                    break; 
                case 'calculateCrc32':
                    priv.filesMetaData[data.fileIndex].crc32CheckSum = data.crc32CheckSum;
                    getProgressAction(data.fileIndex);
                    break; 
            }
        }
        
        function getProgressAction(fileIndex){
            var vFileMeta = priv.filesMetaData[fileIndex],
                vFile = priv.filesToUpload[fileIndex];
            getProgress({
                FileName: vFile.name,
                FileSize: vFile.size,
                FileCRC: vFileMeta.crc32CheckSum,
                UploadRef: vFileMeta.uploadRef
            },function(res){
                if(res !== null){
                    vFileMeta.action = res.action;
                    if(res.action === 'ResumeUpload' && priv.progress.currentBlobIndex < res.chunks.length){
                        shared.paused = true;
                        continueWithLastUploadedChunks(fileIndex,res);
                    }else if(res.action === 'ReuseFileRef'){
                        shared.paused = true;
                        reuseFileRef(fileIndex,res.fileRef);
                    }
                }
      
            });
        }
        
        function continueWithLastUploadedChunks(fileIndex,data){
            var vFileMeta = priv.filesMetaData[fileIndex],
                vChunks = priv.filesBlobMap.filter(function(x){return x.fileIndex === fileIndex});
                
            vFileMeta.uploadRef = data.UploadRef;
            for(var i = 0; i < data.chunks.length; i++){
                if(!vChunks[i].completed){
                    vChunks[i].completed = true;//mark chunks as completed
        
                }
            }
    
            calculateFileTotalUploaded(fileIndex);
            calculateProgressTotalUploaded();
            shared.paused = false;
            

        }
        
        function reuseFileRef(fileIndex,fileRef){
            var vFileMeta = priv.filesMetaData[fileIndex],
                vFile = priv.filesToUpload[fileIndex],
                vBlobs = priv.filesBlobMap.filter(function(x){return x.fileIndex === fileIndex})
            
            for(var i = 0; i< vBlobs.length; i++){
                vBlobs[i].completed = true;//mark chunks as completed
            }
       
            vFileMeta.totalUploaded = vFile.size;
 
            vFileMeta.fileRef = fileRef;
            calculateProgressTotalUploaded();
            shared.paused = false;
    
        }
        
        function calculateFileTotalUploaded(fileIndex){
            var vChunks = priv.filesBlobMap.filter(function(x){return x.fileIndex === fileIndex && x.completed}),
                vFileMeta = priv.filesMetaData[fileIndex];
            vFileMeta.totalUploaded = 0;
            for(var i = 0; i < vChunks.length; i++){
                vFileMeta.totalUploaded += vChunks[i].size - 1;
            }
            
        }
        function calculateProgressTotalUploaded(){
            priv.progress.totalUploaded = 0;
            for(var i = 0; i < priv.filesMetaData.length; i++){
                priv.progress.totalUploaded +=priv.filesMetaData[i].totalUploaded;
            }
           
        }
        
        function terminateWorker(){
            if(priv.worker !== null){
                priv.worker.terminate();
                priv.worker = null;
            }
        }
        
        function calculateSha256(){
            priv.worker.postMessage({'cmd': 'calculateSha256', 'files': priv.filesToUpload,blobMap: priv.filesBlobMap});
        }
        function calculateCrc32(){

            var filesToCalculate = [];
            for(let [key, file] of Object.entries(priv.filesToUpload)){
                if(file.size > priv.options.sliceSize){
                    filesToCalculate.push(file);
                }
            }
            priv.worker.postMessage({'cmd': 'calculateCrc32', 'files': priv.filesToUpload,blobMap: priv.filesBlobMap});
        }
        
        function slice(file, start, end) {
            var slice = file.mozSlice ? file.mozSlice :
                      file.webkitSlice ? file.webkitSlice :
                      file.slice ? file.slice : noop;
            return slice.bind(file)(start, end);
        }
    
        
        function prepareFileName(pFileName){
            var vIlegalChars ="()",
                vFile = encodeURIComponent(pFileName);
            for (var x = 0; x < vIlegalChars.length; x++){
                var c = vIlegalChars.charAt(x);
                vFile = vFile.replace( new RegExp('\\'+c, "g"),escape(c));
            }
 
            return vFile;
        }
        
        function handleUploadCompleted(response){
            fireEvent("onUploaded", response);
            resetProgress();
        }
        
        function handleChunkUploadCompleted(response){
            var currentMeta = priv.filesMetaData[priv.progress.currentFileIndex],
                currentFile = priv.filesToUpload[priv.progress.currentFileIndex],
                chunk = priv.filesBlobMap[priv.progress.currentBlobIndex];
            //lastFileChunk
            //find if it was the last blob

            /***Chunk was uploaded***/
            chunk.completed = true;
            
            priv.progress.totalUploaded += chunk.size-1;
            if(priv.progress.totalUploaded > priv.progress.total-1){
                priv.progress.totalUploaded  = priv.progress.total;
            }
            currentMeta.totalUploaded += chunk.size-1;
            
            if(chunk.lastFileChunk){                   
                currentMeta.fileRef = response.fileRef;
                currentMeta.completed = true;
                if(priv.dataObject !== null) {
                    addOrUpdateDataObjectRow(response.fileRef, currentFile, priv.primKey ?? priv.tmpData.get('PrimKey'));                   
                }
                currentFile.uploaded = true;
                currentMeta.totalUploaded = currentFile.size;
                calculateProgressTotalUploaded();
                var vNotCompletedFiles = priv.filesMetaData.filter(function(x){return !x.completed});
                if(vNotCompletedFiles.length > 0){
                    priv.progress.currentFileIndex = vNotCompletedFiles[0].fileIndex;
                    continueChunkUpload();
                }else{
                    if(priv.dataObject === null) {
                        finalizeUpload();     
                    }              
                    return;
                }
            }else{
                //continue with chunk upload
                currentMeta.uploadRef = response.uploadRef;
                continueChunkUpload();
            }
        }

        function addOrUpdateDataObjectRow(fileRef, file, primKey = null){
            var pData = {};
            if(primKey){
                pData['PrimKey'] = primKey;
            }
            pData['FileName'] = prepareFileName(file.name);
            pData['FileSize'] = file.size;
            pData['FileRef'] = fileRef;

            for (const pair of priv.tmpData.entries()) {
                var fieldName = pair[0];
                var fieldValue = pair[1];
                if(priv.dataObject.hasField(fieldName)) {                    
                    var vField = priv.dataObject.fields.find((x) => x.name == fieldName);
                    if(vField && vField.type == 'number'){
                        fieldValue = parseInt(fieldValue);
                    }                    
                }
                pData[fieldName] = fieldValue;
            }
            if(primKey){
                priv.dataObject.getDataHandler().update(pData, addOrUpdateDataObjectRowCallback);
            } else {
                priv.dataObject.getDataHandler().create(pData, addOrUpdateDataObjectRowCallback);
            }            
        }

        function addOrUpdateDataObjectRowCallback(error, data) {
            if(error){
                console.error(error);
                stopUploadOnError(error.error??error);
            } else {
                var vNotCompletedFiles = priv.filesMetaData.filter(function(x){return !x.completed});
                if(vNotCompletedFiles.length == 0){
                    finalizeUpload();
                }
                console.log(data)
            }
        }
        
        function continueChunkUpload(){

            var vCurrentBlob = priv.filesBlobMap[priv.progress.currentBlobIndex],
                vCurrentMeta = priv.filesMetaData[vCurrentBlob.fileIndex];
            
            if(vCurrentMeta.crc32CheckSum !== null && vCurrentMeta.action === null){
                getProgressAction(priv.progress.currentFileIndex);
            }
            var notCompletedBlobs = priv.filesBlobMap.filter(function(x){return !x.completed && x.fileIndex === priv.progress.currentFileIndex});
            var vNotCompletedFiles = priv.filesMetaData.filter(function(x){return !x.completed});
          
            if(notCompletedBlobs.length === 0 && !vCurrentMeta.completed){
                continueFileUpload(vNotCompletedFiles);
            }else if(notCompletedBlobs.length === 0 && vCurrentMeta.completed){
                notCompletedBlobs = priv.filesBlobMap.filter(function(x){return !x.completed});
                if(notCompletedBlobs.length){
                    priv.progress.currentBlobIndex = notCompletedBlobs[0].index;//Grab first not completed one
                    priv.progress.currentFileIndex = vNotCompletedFiles[0].fileIndex;
                    reportProgress();
                    uploadChunk();
                }else{
                    continueFileUpload(vNotCompletedFiles);
                }
                
            }else if(notCompletedBlobs.length > 0){
                priv.progress.currentBlobIndex = notCompletedBlobs[0].index;//Grab first not completed one
                reportProgress();
                uploadChunk();
            }
   
        }
        
        function continueFileUpload(pNotCompletedFiles){
            if(pNotCompletedFiles.length > 0){
                // var formData = getFormData();
                priv.progress.currentFileIndex = pNotCompletedFiles[0].fileIndex;
                // formData.append('FileRef',pNotCompletedFiles[0].fileRef);
                uploadChunk();
                // uploadFile(formData);
            }else{
                finalizeUpload()
            }
        }
        
        function finalizeUpload(){
             var res = null,
                responseArray = {};
            if(priv.filesMetaData.length === 1){
                res = priv.filesMetaData[0].fileRef;
            }else{
                for(var y = 0; y < priv.filesMetaData.length; y++){
                    responseArray[y] = priv.filesMetaData[y].fileRef;
                }
            }
            priv.tmpData = null;
            terminateWorker();
            handleUploadCompleted(res !== null ? res : responseArray);

        }

        function reportProgress(pUploaded){
            if(shared.paused ){
                return;
            }
            if(!shared.progress){
               initProgress(); 
            }
            if(!shared.progress.is(":visible")){
                showProgress();
            }
           
            var  uploadedDiff;
            if(priv.useChunkUpload){
                var vCurrentFile = priv.filesToUpload[priv.progress.currentFileIndex],
                    vCurrentMeta = priv.filesMetaData[priv.progress.currentFileIndex],
                    vUploaded = 0;
                    
                if(vCurrentMeta && vCurrentFile){
                   
                    
                    if(pUploaded){
                        if(isNaN(parseFloat(pUploaded))){
                            priv.progress.totalUploaded = priv.progress.total;
                        }else{
                            vUploaded = pUploaded;
                        }
                    }
                    
                    if(vUploaded > vCurrentFile.size){
                        vUploaded = vCurrentFile.size;
                    }
                    if(vCurrentFile.size < vCurrentMeta.totalUploaded){
                        vUploaded = 0;
                        vCurrentMeta.totalUploaded = vCurrentFile.size;
                    }

                    shared.progress.find("[data-total]").text(priv.filesToUpload.length);
                    shared.progress.find("[data-current]").text(priv.progress.currentFileIndex+1);
                    shared.progress.find(".fileUploadContainer").empty();   
               
                    var tmpUploaded = vCurrentMeta.totalUploaded + vUploaded;
                    if(vCurrentFile.size < tmpUploaded){
                        tmpUploaded = vCurrentFile.size;
                    }
                    appendProgressContainer({name:vCurrentFile.name, size:vCurrentFile.size, uploaded:tmpUploaded});
                   
                    if(priv.filesToUpload.length > 1){
                        if(priv.progress.totalUploaded > priv.progress.total){
                            priv.progress.totalUploaded = priv.progress.total;
                            vUploaded = 0;
                        }
                        appendProgressContainer({name:"", size:priv.progress.total, uploaded:priv.progress.totalUploaded + vUploaded});
                    }
                }
            }else{
                shared.progress.find(".fileUploadContainer").empty();
                shared.progress.find("[data-total]").text(priv.filesToUpload.length);
                shared.progress.find("[data-current]").text(priv.filesToUpload.length);
                if(pUploaded && isNaN(parseFloat(pUploaded))){
                    priv.progress.totalUploaded = priv.progress.total;
                }else{
                       
                    uploadedDiff = pUploaded - priv.progress.tmpUploaded;
                    if(uploadedDiff > priv.progress.total){
                        uploadedDiff = priv.progress.total;
                    }
                    priv.progress.tmpUploaded = pUploaded;
                    priv.progress.totalUploaded += uploadedDiff;
                }
                appendProgressContainer({name:"", size:priv.progress.total, uploaded:priv.progress.totalUploaded});
                if(priv.progress.totalUploaded >= priv.progress.total){
                    priv.progress.completed = true;
                    
                }
            }
        }
        
        function appendProgressContainer(pOptions){
            var vUploaded = pOptions.size > 0?pOptions.uploaded / pOptions.size:1;
            var vFileContainer = $(af.template.run(shared.progressFileMarkup, {
                   FileName:pOptions.name,
                   FileUploadedSize:af.common.formatFileSize(pOptions.uploaded,2),
                   FileTotalSize:af.common.formatFileSize(pOptions.size,2),
                   UploadedPercents: Math.round(vUploaded * 100,0),
                   of_total:shared.progressTotalLabel,
                }));
            vFileContainer.find(".progress-bar").css("width",vUploaded * 100 + "%");
 
            shared.progress.find(".fileUploadContainer").append(vFileContainer);

        }
        
        function hideFileProgress(){
            if(priv.filesMetaData.length > 1 && priv.useChunkUpload){
                shared.progress.find(".fileUploadContainer .progress:first").fadeOut( "slow",function(){$(this).remove();} );
                shared.progress.find(".fileUploadContainer [data-progress-info]:first").fadeOut( "slow",function(){$(this).remove();} );
            }
        }
        function showProgress(){
         
            shared.progress.find(".cancelUpload").removeClass("hidden");
            shared.progress.find(".fileErrorContainer").addClass("hidden");
            shared.progress.find(".closeUpload").addClass("hidden");
            shared.progress.find(".uploadingProgress").removeClass("hidden");
            shared.progress.find(".uploadComplete").addClass("hidden");
            shared.progress.find(".uploadsComplete").addClass("hidden");
            shared.progress.find(".pauseUpload").removeClass("hidden");
    
            if(priv.showProgress){
                shared.progress.modal("show");
            }

        }
        
        function resetProgress(){
            if(shared.backdrop){
                shared.backdrop.addClass("hide");
            }
            
            if(priv.filesMetaData.length === 1){
                shared.progress.find(".uploadComplete").removeClass("hidden");
            }else{
                shared.progress.find(".uploadsComplete").removeClass("hidden");
                shared.progress.find(".uploadsComplete").text(("{0} " + aT("files uploaded")).replace("{0}",priv.filesMetaData.length));
            }
            
            shared.progress.find('.progress-bar').width('100%').text('100 %');             
            shared.progress.find(".cancelUpload").addClass("hidden");
            shared.progress.find(".closeUpload").removeClass("hidden");
            shared.progress.find(".pauseUpload").addClass("hidden");
            shared.progress.find(".uploadingProgress").addClass("hidden");
            shared.progress.find(".fileErrorContainer").addClass("hidden");
            
            hideFileProgress();
            
            priv.filesToUpload = {};
            priv.blob = [];
            priv.xhr = null;
            priv.filesArray = [];
            if(af.common.localStorage.get(shared.autoCloseKey,true,true)){
                setTimeout(closeProgress(), 2000);
                
            }
        }
        
        function closeProgress(){
            terminateWorker();
            shared.progress.find(".fileUploadContainer").empty();
            shared.progress.modal("hide");
            if($(priv.element).closest(".modal").length){
                $("body").addClass("modal-open");
            }
            
        }
      
        
        function cancelFileUpload(){
          
            fireEvent("onCancel",priv.filesToUpload);
           
            terminateWorker();
            var vCompletedFiles = [];
            for(var i = 0; i < priv.filesToUpload; i++){
                if(priv.filesToUpload[i].uploaded){
                    vCompletedFiles.push(priv.filesToUpload[i]);
                }
            }
            if(vCompletedFiles.length > 0){
                if(priv.dataObject){
                    priv.dataObject.refreshDataSource();
                }
            }
            //ask what to do if there is already files uploaded
            shared.progress.find(".fileUploadContainer").empty();
            shared.progress.modal("hide");
            priv.filesToUpload = {};
            priv.progress.currentFileIndex = 0;
            priv.blob = [];
            priv.currentBlobIndex = 0;
            priv.progress = [];
            priv.filesArray = [];
            priv.fileInput.value = "";
        }
        
        function resumeFileUpload(){
            shared.paused = false;
            continueChunkUpload();
        }
        
        function retryFileUpload(){
            if(priv.interval.repeatCounter === null){
                return;
            }
            shared.paused = false;
            continueChunkUpload();
        }
        
        function stopUploadOnError(error, network){
            shared.paused = true;
            if(network){
                priv.error = error;
                if(priv.interval.retryCounter > 0){
                    repeatFileRetry();
                }else{
                    shared.progress.find(".pauseUpload").text(aT("Retry"));
                }
            }else{
                shared.progress.find(".pauseUpload").addClass('hidden');
                priv.error = error;
            }
     
            fireEvent("onError", error);
            shared.progress.find(".fileErrorContainer").text(priv.error);
            shared.progress.find(".fileErrorContainer").removeClass('hidden');
        }
        
        function repeatFileRetry(){

            /*********
             * Repeat 3 times
             * Count from 30 to 1
            
            *********/
            if(priv.interval.repeatCounter === null){
                return;
            }
            priv.interval.interval = setInterval(function(){
              
                if(priv.interval.repeatCounter > 0){
                    addCounterToBtn(priv.interval.repeatCounter);
                    priv.interval.repeatCounter -= 1;
                }else{
                    clearInterval(priv.interval.interval);
                    priv.interval.interval = null;
                    priv.interval.repeatCounter = 30;
                    priv.interval.retryCounter -=1;
                    retryFileUpload();
                }
                
                
            }, 1000);
        }
        function addCounterToBtn(count){
            window.requestAnimationFrame(function(){
                shared.progress.find(".pauseUpload").text(aT("Retrying in") + " "+ count +" s");
            });
        }
        
        function pauseFileUpload(){
            shared.paused = true;
        }
        
        function hideModal(){
            shared.progress.modal("hide");
        }
        
        function getDataObject(){
            if(!priv.dataObject){
                priv.dataObject = af.DataObject.getByID($(priv.element).closest("[data-object-id]").data("object-id"));
            }
            return priv.dataObject;
        }
        
        function handleOfflineUpload(){
            if(window['af.pwa.offlineDataHandler']){
                window['af.pwa.offlineDataHandler'].addFilesToIndexDB(priv.filesToUpload,getFormData(),priv.dataObject,priv.uploadUrl).then(function(pFiles){
                    shared.paused = false;
                    reportProgress('all');
                    shared.paused = true;
                    handleUploadCompleted(pFiles);
                });
            }else{
                stopUploadOnError('Network is not accessible',true);
            }
        }
        
        function addExifData(status){
            priv.addExifData = status;
        }
        
        function getExifData(pCallback){
            if(priv.filesToUpload && priv.filesToUpload.length === 0){
                if(typeof pCallback === "function"){
                    pCallback(null)
                }
                return;
            }
            if(priv.exifData !==null){
                if(typeof pCallback === "function"){
                    pCallback(priv.filesToUpload)
                }
                return;
            }
            parseExif(0,function(pFiles){
                priv.exifData = pFiles;
                if(typeof pCallback === "function"){
                    pCallback(priv.filesToUpload)
                }
            });
        }
        
        function parseExif(pIndex,pCallback){
            if(priv.filesToUpload.length === pIndex){
                pCallback();
                return;
            }
            var vFile = priv.filesToUpload[pIndex];
            EXIF.getData(vFile, function() {
               parseExif(pIndex+1,pCallback);
            });
        }
        
        function getCurrentProcessedFile(){
            return priv.filesToUpload[priv.progress.currentFileIndex !== undefined ? priv.progress.currentFileIndex : 0];
        }
         
        function clearFiles(){
            priv.filesToUpload = {};
            priv.progress.currentFileIndex = 0;
            priv.blob = [];
            priv.currentBlobIndex = 0;
            priv.progress = [];
            priv.filesArray = [];
            priv.fileInput.value = "";
        }
        
        function getUploadUrl(){
            if(!priv.dataObject){
                priv.dataObject = af.DataObject.getByID($(priv.element).closest("[data-object-id]").data("object-id"));
            }
           
            if(priv.routeUrl || priv.binaryField){
                var vRow = priv.dataObject?priv.dataObject.currentRow():null;
                    if(priv.binaryField !== "" && !priv.uploadUrl){
                         //priv.uploadUrl = "/file/upload/" + priv.dataObject.getDatasourceArticleID() + "/" + priv.dataObject.getDataSourceId() + "/" + priv.binaryField;
                         priv.uploadUrl = '/api/file/chunkupload';
                         priv.routeUrl =  priv.uploadUrl;
                    }else{
                    if(vRow){
                        priv.uploadUrl = af.dataBinding.Helpers.validateSrcUrl(null,priv.routeUrl, vRow);    
                    }else{
                        priv.uploadUrl = priv.routeUrl;
                    }
                }                
            }else if(!priv.uploadUrl && priv.dataObject){
                priv.uploadUrl = (priv.options.uploadUrl !== null ?priv.options.uploadUrl : ("/api/file/chunkupload" + priv.dataObject.getDatasourceArticleID() + "/" + priv.dataObject.getDataSourceId() + "/" + priv.binaryField));
            }
            return priv.uploadUrl;
        }
        
        function setCustomHeaders(headers) {
            if (priv) {
                priv.customHeaders = headers;
                return true;
            }
            return false;
        }

        function handlePaste(e){
            if (priv.disableUpload) {
                return;
            }

            if(af.common.isIE()){
                /**To do */
                return;
            } else {
                var items = (e.clipboardData || e.originalEvent.clipboardData).items;
                if(items.length > 0 && items[0].kind === 'file'){
                    var vFile = e.clipboardData.items[0].getAsFile();
                    var vObj = new Object();
                    vObj.File = vFile;
                    if(vObj.File && fireEvent("onBeforePaste", vObj, true) !== false){
                        addBlob(vObj.File);
                        save(function (pFiles) {
                            fireEvent("onFilesUploaded", pFiles);
                        });
                        e.preventDefault();
                    }
                }
            }
        }

        /**
         * bind some element for paste Event 
         *  and upload file to specified instance
         * @function
         * @param {HTMLElement} pContainer - selector to bind paste Event to.
        */
        function bindPasteContainer(pContainer){
            if(priv.disablePasteEvent) return;
            pContainer.addEventListener("paste",handlePaste);
            priv.pasteEventBinded = true;
        }

        /**
         * checks if paste container was added and event binded
         * @function
        */
        function isPasteEventBinded(){
            return priv.pasteEventBinded;
        }

         /**
         * get upload container
         * @function
         * @returns {HTMLElement}
        */
        function getContainer(){
            return priv.element;
        }

        /**
         * @Description - setMultipleFilesPathField used to set uploader to allow directory upload
         *  @Parameters:
         *      @pField : String - set dsObject.field which will be used to store RelativePath
         * 
         * @Warning - do not use this in production mode as it does not work in IE11 and it is experimental feauture
         * 
         */
        function setMultipleFilesPathField(pField){
            priv.multipleFilesPathField = pField;
            priv.fileInput.webkitdirectory = true;
        }

        function getID(){
            return priv.pOptions.id;
        }

        function disableUpload(pDisable){
            priv.disableUpload = pDisable;
        }
      
        function setWhitelistMessage(pMessage){
            priv.whitelistMessage = pMessage;
        }

        that.attachEvent = attachEvent;
        that.detachEvent = detachEvent;
        that.getElement = getElement;
        that.setCurrentPrimKey = setCurrentPrimKey;
        that.appendFiles = appendFiles;
        that.removeFile = removeFile;
        that.manualUpload = manualUpload;
        that.files = files;
        that.save = save;
        that.addBlob = addBlob;
        this.getBackdrop = getBackdrop;
        this.getDropHoverText = getDropHoverText;
        this.onDrop = onDrop;
        this.getElement = getElement;
        this.hasBackdrop = hasBackdrop;
        this.getXhr = getXhr;
        this.cancelFileUpload = cancelFileUpload;
        this.getFilesCount = getFilesCount;
        this.hideModal = hideModal;
        this.getFormData = getFormData;
        this.getDataObject = getDataObject;
        this.removeDeletedFiles = removeDeletedFiles;
        this.initInterval = initInterval;
        this.resumeFileUpload = resumeFileUpload;
        this.removeAllFiles = removeAllFiles;
        this.addExifData = addExifData;
        this.getCurrentProcessedFile = getCurrentProcessedFile;
        this.addFiles = addFiles;
        this.clearFiles = clearFiles;
        this.getUploadUrl = getUploadUrl;
        this.setCustomHeaders = setCustomHeaders;
        this.setMultipleFilesPathField = setMultipleFilesPathField;
        this.bindPasteContainer = bindPasteContainer;
        this.getID = getID;
        this.isPasteEventBinded = isPasteEventBinded;
        this.getContainer = getContainer;
        this.disableUpload = disableUpload;
        this.setWhitelistMessage = setWhitelistMessage;
        
        initialize();
    }
    c.expose("af.dataBinding", FileUpload);

    function getExtensionFromMIME(pMIMEType){
        switch(pMIMEType){
            case 'audio/aac':
                return 'aac'
            break;
            case 'application/x-abiword':
                return 'abw'
            break;
            case 'application/x-freearc':
                return 'arc'
            break;
            case 'video/x-msvideo':
                return 'avi'
            break;
            case 'application/vnd.amazon.ebook':
                return 'azw'
            break;
            case 'application/octet-stream':
                return 'bin'
            break;
            case 'image/bmp':
                return 'bmp'
            break;
            case 'application/x-bzip':
                return 'bz'
            break;
            case 'application/x-bzip':
                return 'bz2'
            break;
            case 'application/x-bzip2':
                return 'bz'
            break;
            case 'application/x-csh':
                return 'csh'
            break;
            case 'text/css':
                return 'css'
            break;
            case 'text/csv':
                return 'csv'
            break;
            case 'application/msword':
                return 'doc'
            break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'docx'
            break;
            case 'application/epub+zip':
                return 'epub'
            break;
            case 'application/gzip':
                return 'gz'
            break;
            case 'image/gif':
                return 'gif'
            break;
            case 'text/html':
                return 'html'
            break;
            case 'image/vnd.microsoft.icon':
                return 'ico'
            break;
            case 'text/calendar':
                return 'ics'
            break;
            case 'application/java-archive':
                return 'jar'
            break;
            case 'image/jpeg':
                return 'jpg'
            break;
            case 'text/javascript':
                return 'js'
            break;
            case 'application/json':
                return 'json'
            break;
            case 'application/ld+json':
                return 'jsonld'
            break;
            case 'audio/midi':
            case 'audio/x-midi':
                return 'mid'
            break;
            case 'audio/mpeg':
                return 'mp3'
            break;
            case 'video/mpeg':
                return 'mpeg'
            break;
            case 'application/vnd.apple.installer+xml':
                return 'mpkg'
            break;
            case 'application/vnd.oasis.opendocument.presentation':
                return 'odp'
            break;
            case 'application/vnd.oasis.opendocument.spreadsheet':
                return 'ods'
            break;
            case 'application/vnd.oasis.opendocument.text':
                return 'odt'
            break;
            case 'audio/ogg':
                return 'oga'
            break;
            case 'video/ogg':
                return 'ogv'
            break;
            case 'application/ogg':
                return 'ogx'
            break;
            case 'audio/opus':
                return 'opus'
            break;
            case 'font/otf':
                return 'otf'
            break;
            case 'image/png':
                return 'png'
            break;
            case 'application/pdf':
                return 'pdf'
            break;
            case 'application/php':
                return 'php'
            break;
            case 'application/vnd.ms-powerpoint':
                return 'ppt'
            break;
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'pptx'
            break;
            case 'application/vnd.rar':
                return 'rar'
            break;
            case 'application/rtf':
                return 'rtf'
            break;
            case 'application/x-sh':
                return 'sh'
            break;
            case 'image/svg+xml':
                return 'svg'
            break;
            case 'application/x-shockwave-flash':
                return 'swf'
            break;
            case 'application/x-tar':
                return 'tar'
            break;
            case 'application/pdf':
                return 'pdf'
            break;
            case 'image/tiff':
                return 'tiff'
            break;
            case 'video/mp2t':
                return 'ts'
            break;
            case 'font/ttf':
                return 'ttf'
            break;
            case 'text/plain':
                return 'txt'
            break;
            case 'application/vnd.visio':
                return 'vsd'
            break;
            case 'audio/wav':
                return 'wav'
            break;
            case 'audio/webm':
                return 'weba'
            break;
            case 'video/webm':
                return 'webm'
            break;
            case 'image/webp':
                return 'webp'
            break;
            case 'font/woff':
                return 'woff'
            break;
            case 'font/woff2':
                return 'woff2'
            break;
            case 'application/xhtml+xml':
                return 'xhtml'
            break;
            case 'application/vnd.ms-excel':
                return 'xls'
            break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'xlsx'
            break;
            case 'application/xml':
                return 'xml'
            break;
            case 'application/vnd.mozilla.xul+xml':
                return 'xul'
            break;
            case 'application/zip':
                return 'xul'
            break;
            case 'video/3gpp':
                return 'xul'
            break;
            case 'audio/3gpp':
                return 'xul'
            break;
            case 'video/3gpp2':
                return 'xul'
            break;
            case 'audio/3gpp2':
                return 'xul'
            break;
            case 'application/x-7z-compressed':
                return 'xul'
            break;
        }
    }

} (this));